import React, { FC } from 'react';
import { createBemHelper } from 'utils/BEM';
import './ImageBar.scss';

const BEM = createBemHelper('image-bar');

export interface ImageBarProps {
  imageSrc: string;
}

export const ImageBar: FC<ImageBarProps> = props => {
  return (
    <div
      className={BEM()}
      style={{
        backgroundImage: `url(${props.imageSrc})`,
      }}
    >
      <div className={BEM('content')}>{props.children}</div>
    </div>
  );
};
