import React, { FC } from 'react';
import { createBemHelper } from 'utils/BEM';
import './HeroGrid.scss';
import { Button } from 'components/Button/Button';

const BEM = createBemHelper('hero-grid');

export interface HeroGridProps {
  text: {
    heading: string;
    subheading: string;
  };
  imageSrc: string;

  onShowBooking: () => void;
}

export const HeroGrid: FC<HeroGridProps> = props => {
  const { text, imageSrc, onShowBooking } = props;
  return (
    <div className={BEM()}>
      <div className={BEM('hero-text')}>
        <div className={BEM('hero-heading')}>{text.heading}</div>
        <div className={BEM('hero-subheading')}>{text.subheading}</div>
        <div className={BEM('hero-cta')}>
          <Button size={'large'} onClick={onShowBooking}>
            BOOK NOW
          </Button>
        </div>
      </div>
      {/* <div className={BEM('hero-image')}>
        <img src={imageSrc} />
      </div> */}
      <img src={imageSrc} />
    </div>
  );
};
