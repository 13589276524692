import React, { FC } from 'react';
import { createBemHelper } from 'utils/BEM';
import { Page } from 'components/Page/Page';
import { Button } from 'components/Button/Button';
import { useStaticQuery, graphql } from 'gatsby';
import './Landing.scss';
import { SocialMediaBar } from 'components/SocialMediaBar/SocialMediaBar';
import { ImageBar } from 'components/ImageBar/ImageBar';
import { Icon } from 'components/Icon/Icon';
import Media from 'react-media';
import { HeroGrid } from 'components/HeroGrid/HeroGrid';

const BEM = createBemHelper('landing');

export interface LandingProps {
  onShowBooking: () => void;
  hero: {
    heading: string;
    subheading: string;
  };
}

export const Landing: FC<LandingProps> = props => {
  // Hero will be populated by index
  const { onShowBooking, hero = { heading: undefined, subheading: undefined } } = props;

  const data = useStaticQuery(graphql`
    query LandingPageQuery {
      heroImage: file(relativePath: { eq: "stock-images/breakfast-3.png" }) {
        childImageSharp {
          fixed(width: 1400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      inaNortje: file(relativePath: { eq: "ina-nortje.png" }) {
        childImageSharp {
          fixed(width: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      banner1: file(relativePath: { eq: "stock-images/citrus-1.png" }) {
        childImageSharp {
          fixed(width: 1400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      banner2: file(relativePath: { eq: "stock-images/fruit-2.png" }) {
        childImageSharp {
          fixed(width: 1400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      serviceWeightLoss: file(relativePath: { eq: "stock-images/breakfast-2.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      serviceDiseaseManagement: file(relativePath: { eq: "stock-images/pills-1.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      serviceLifestyleGenetics: file(relativePath: { eq: "stock-images/pineapple-1.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      serviceGastroIntestinal: file(relativePath: { eq: "stock-images/breakfast-6.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      serviceSixWeekDetox: file(relativePath: { eq: "stock-images/greens-1.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      serviceFoodSensitivity: file(relativePath: { eq: "stock-images/bread-1.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      testimonialBackdrop: file(relativePath: { eq: "stock-images/lemons-1.jpg" }) {
        childImageSharp {
          fixed(width: 2000, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Page onShowBooking={onShowBooking} title={'Home'}>
      <div className={BEM()}>
        {hero.heading ? (
          <HeroGrid text={hero} imageSrc={data.heroImage.childImageSharp.fixed.src} onShowBooking={onShowBooking} />
        ) : (
          <div className={BEM('empty-hero')}> </div>
        )}
        <SocialMediaBar />
        <ImageBar imageSrc={data.banner1.childImageSharp.fixed.src}>
          <span className={BEM('image-bar-text')}>
            <Media
              queries={{
                phablet: '(max-width: 560px)',
                large: '(min-width: 561px)',
              }}
            >
              {matches => {
                return <>Stay home.{matches.phablet ? <br /> : ' '}Book a virtual consultation.</>;
              }}
            </Media>
          </span>
        </ImageBar>
        <div className={BEM('services-text')}>
          <div className={BEM('services-heading')}>Specialties</div>
          <div className={BEM('services-subtext')}>
            Ina Nortjé offers a wide range of different services and programs for you to reach your health goals.
            Services include guided and customized programs with meal plans that are specially designed to consider your
            individual lifestyle and dietary requirements.
          </div>
          <div className={BEM('services-cta')}>
            <Button size={'large'} onClick={onShowBooking}>
              BOOK NOW
            </Button>
          </div>
          <div className={BEM('service-card-grid')}>
            {buildServiceCard(
              'Disease Management',
              'A major (and often neglected) part of mastering well-controlled chronic illnesses – such as hypertension, diabetes etc. - is DIET. Learn how to beat the daily pills with a healthy lifestyle. Find out which foods are good for your condition and which absolutely detrimental, and take ownership of your health today!',
              data.serviceDiseaseManagement.childImageSharp.fixed.src
            )}
            {buildServiceCard(
              'DNA & Gene Testing',
              'Take a genetic test to find out which of your genes are better expressed through adaptable simple lifestyle interventions. You could be missing out on your best quality life by simply following the wrong diet and lifestyle.',
              data.serviceLifestyleGenetics.childImageSharp.fixed.src,
              true
            )}
            {buildServiceCard(
              'Food Sensitivity Test',
              'Bloating, eczema and fatigue could be an indicator of underlying food allergies – causing you severe discomfort without knowing what the trigger is. Take a simple, thorough food sensitivity test to guide you in the diagnosis of a food sensitivity. Let Ina help you manage these symptoms without missing out on a wholesome, flavourful diet.',
              data.serviceFoodSensitivity.childImageSharp.fixed.src,
              true
            )}
            {buildServiceCard(
              'Gastro-Intestinal Support',
              'Do you often suffer from abdominal bloating? Nausea? Diarrhoea? Stomach cramps? You could be suffering from Irritable Bowel Syndrome or other gastro-intestinal problems. Let Ina guide you on your journey to a healthy gut and improve your quality of life through proper education and fun adaptations.',
              data.serviceGastroIntestinal.childImageSharp.fixed.src
            )}
            {buildServiceCard(
              'Weight Loss',
              'Losing weight doesn’t have to be the tiring struggle we all know it to be. With Ina’s help, you could learn all you need to know about nutritious food, portion control and flexible dieting. Yes, you can eat chocolate and lose weight! Contact Ina to find out how.',
              data.serviceWeightLoss.childImageSharp.fixed.src
            )}
            {buildServiceCard(
              'Detox Programme',
              'Detoxification is the cornerstone of happy, healthy living. Through this programme we focus on restoring all phytonutrients, supporting the liver and the body from harmful toxins and revitalising energy levels in a fun, healthy and nutritious way.',
              data.serviceSixWeekDetox.childImageSharp.fixed.src
            )}
          </div>
        </div>
        <ImageBar imageSrc={data.banner2.childImageSharp.fixed.src}>
          <span
            className={BEM('image-bar-text')}
            style={{
              color: 'var(--color-dark)',
            }}
          >
            <Media
              queries={{
                phablet: '(max-width: 560px)',
                large: '(min-width: 561px)',
              }}
            >
              {matches => {
                // return <>Don't stress.{matches.phablet ? <br /> : ' '}Do a gene test.</>;
                return <>Don't stress. Do a gene test. &#128512;</>;
              }}
            </Media>
          </span>
        </ImageBar>
        <div className={BEM('meet-ina')}>
          <div className={BEM('meet-ina-image')} data-aos="fade-up" data-aos-once="true">
            <img src={data.inaNortje.childImageSharp.fixed.src} />
          </div>
          <div className={BEM('meet-ina-text')} data-aos="fade-up" data-aos-once="true">
            <div className={BEM('meet-ina-text-container')}>
              <div className={BEM('meet-ina-heading')}>
                <div className={BEM('meet-ina-heading-name')}>Meet Ina Nortjé</div>
                <div className={BEM('meet-ina-heading-title')}>Dietitian (RD) and Wellness Coach</div>
              </div>
              <div className={BEM('meet-ina-description')}>
                <p>
                  Ina has more than 35 years’ experience in how to add value to clients' health destiny. She has a
                  passion and holistic approach using various well-being modalities to interpret results and info into
                  practical hands on advice to turn health around.
                </p>
                <p>
                  Ina’s goal and belief is to play a significant role in making you more aware how lifestyle can affect
                  your health and to give you the tools to take control and reach your health goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={BEM('testimonials')}
          style={{
            backgroundImage: `url(${data.testimonialBackdrop.childImageSharp.fixed.src})`,
          }}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className={BEM('testimonial-heading')}>
            <div>
              Some <Icon className={'ri-heart-fill'} color={'var(--color-primary)'} /> from Ina Nortjé’s customers
            </div>
          </div>
          <div className={BEM('testimonial-card-grid')}>
            {buildTestimonialCard(
              'Karen Joubert',
              'London',
              'After many years of having undiagnosed food sensitivities and digestive problems, I was finally referred to Ina. I was in a state of complete exhaustion, badly underweight and with a long list of food intolerances. After walking a road with Ina, I am now in a position where I am starting to gain weight, my energy levels are good, and I am finding better ways to cope with stress. Thank you, Ina, for your ingenuity, your passion and always keeping me up to date with the latest research.'
            )}
            {buildTestimonialCard(
              'Gerrie Cronjé',
              'Johannesburg',
              "I’ve been a customer of Ina’s practice for more than 25 years. I am in much better shape today than I was 25 years ago, regardless of an older body and being diagnosed with seronegative spondyloarthritis. And that is all because of this scientist (that's right - scientist, not just a dietician). Ina, thank you. You started this exceptionally long journey with me, focusing on my digestive system and getting me to a position where my body could start healing itself. When I met Ina, I could not finish nine holes of golf – today, I can play three rounds of 18 holes per week!"
            )}
            {buildTestimonialCard(
              'Carol Burrow',
              'Johannesburg',
              'Ina is an incredible caring & knowledgeable person. She goes out of her way to do what is best for her client. Efficiency is another amazing quality of her. Nothing is ever too much trouble'
            )}
            {buildTestimonialCard(
              'Verona Cronjé',
              'George',
              "Our entire family has been working with Ina for the past 27 years. Ina has the knack of investigating like Sherlock Holmes - by diligently reviewing clinical records, compassionately listening to her patients' stories and by utilizing groundbreaking methodologies. She is sharp, knowledgeable and I often feel that I receive better care and insight from her than I do by working with other primary care doctors. She is an absolute champion - always cheering you on to make the right changes to your lifestyle in a world where confusing opinions can send you down the wrong pathway."
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

const buildServiceCard = (heading: string, text: string, imageSrc: string, emailForMoreInfo = false) => {
  return (
    <div className={BEM('service-card')} data-aos="fade-up" data-aos-once="true">
      <div className={BEM('service-card-image-container')}>
        <div className={BEM('service-card-image')} style={{ backgroundImage: `url(${imageSrc})` }}></div>
      </div>
      <div className={BEM('service-card-heading')}>{heading}</div>
      <div className={BEM('service-card-divider')} />
      <div className={BEM('service-card-text')}>{text}</div>
      {emailForMoreInfo ? (
        <div className={BEM('email-more-info')}>
          <i className="ri-mail-line"></i> Only bookable via e-mail.
        </div>
      ) : undefined}
    </div>
  );
};

const buildTestimonialCard = (customerName: string, customerLocation: string, text: string) => {
  return (
    <div className={BEM('testimonial-card')}>
      <div className={BEM('testimonial-customer-name')}>{customerName}</div>
      <div className={BEM('testimonial-customer-location')}>{customerLocation}</div>
      <div className={BEM('testimonial-text')}>{text}</div>
    </div>
  );
};

export default Landing;
