import React from 'react';
import { graphql } from 'gatsby';

import { Landing } from '../lib/Landing/Landing';
import { randomHero } from 'utils/LandingAB';
import Modal from 'react-modal';
import Iframe from 'react-iframe';
import { Helmet } from 'react-helmet';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import './index.scss';
const ROBOTDESK_URL = 'https://bookings.robotdesk.co.za/inanortje/ina';

const BlogIndex = ({ data, location }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [heroAB, setHeroAB] = React.useState();
  let iframe;

  React.useEffect(() => {
    setHeroAB(randomHero());
  }, []);

  iframe = (
    <Iframe
      url={ROBOTDESK_URL}
      frameBorder="0"
      id="myId"
      className="booking-iframe-container"
      display={'initial'}
      position="relative"
    />
  );

  function openModal() {
    trackCustomEvent({
      category: 'Book Now',
      action: 'click',
      // Hack, find a better way
      label: [{ hero: heroAB.id }].map(JSON.stringify).join(' | '),
    });
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Helmet>
        <link rel="preload" href={ROBOTDESK_URL} as={'fetch'} />
        <script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/a60474a8f7e307bae3625271/script.js"
        ></script>
      </Helmet>
      <Modal isOpen={modalIsOpen} htmlOpenClassName="ReactModal__Html--open">
        <div
          style={{
            height: '100%',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: '10',
              cursor: 'pointer',
            }}
          >
            <i
              className={'ri-close-line'}
              style={{
                fontSize: '16px',
              }}
              onClick={closeModal}
            ></i>
          </div>
          {iframe}
        </div>
      </Modal>
      <Landing hero={heroAB} onShowBooking={openModal} />
    </>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
